<script setup lang="ts">
import { useSocialsStore } from '~/store/socials'

const social = useSocialsStore()
</script>

<template>
  <div class="app-advertising">
    <h3 class="app-advertising__title">
      Лень выходить за продуктами?
    </h3>
    <p class="app-advertising__subtitle">
      привезём продукты от 30 мин
    </p>
    <div class="app-advertising__desc">
      скачивайте приложение
    </div>
    <div class="app-advertising__links">
      <a
        v-if="social.googlePlay"
        :href="social.googlePlay"
        target="_blank"
        class="app-advertising__link app-advertising__link_android"
      >
        <img
          class="app-advertising__link-img"
          src="./asset/android.svg"
          alt="Android"
        >
      </a>
      <a
        v-if="social.appStoreUrl"
        :href="social.appStoreUrl"
        target="_blank"
        class="app-advertising__link app-advertising__link_apple"
      >
        <img
          class="app-advertising__link-img"
          src="./asset/apple.svg"
          alt="Apple"
        >
      </a>
    </div>
  </div>
</template>

<style lang="postcss" scoped>
.app-advertising {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  height: 288px;
  padding: 25px 30px 30px;
  background-color: #00B0B8;
  background-image: url('./asset/app-lomtik.svg'), url('./asset/cubes.svg');
  background-repeat: no-repeat, no-repeat;
  background-position: 100% 30%, 103% 30%;
  background-size: 458px 332px, 566px 381px;
  border-radius: 20px;

  &__title {
    margin-bottom: 15px;
    font-family: 'RightGrotesk', sans-serif;
    font-size: 40px;
    font-weight: 500;
    line-height: 40px;
    color: #FFFFFF;
  }

  &__subtitle {
    font-size: 20px;
    line-height: 24px;
    color: #FFFFFF;
  }

  &__desc {
    margin-top: auto;
    font-size: 14px;
    color: #FFFFFF;
  }

  &__links {
    display: flex;
    flex-wrap: wrap;
    gap: 5px;
    margin-top: 8px;

  }

  &__link {
    padding: 8px 24px;
    background-color: #15252B;
    border-radius: 5px;
  }

  @media (max-width: 1460px) {

    &__title  {
      max-width: 288px;
    }
  }

}

@media (max-width: 600px) {

  .app-advertising {
    align-items: center;
    height: 415px;
    background-position: 100% 110%, 80% 0;
    background-size: 330px 250px, 566px 381px;

    &__title {
      order: 1;
      max-width: 185px;
      font-size: 26px;
      line-height: 26px;
    }

    &__subtitle {
      order: 2;
      margin-top: 13px;
      font-size: 16px;
      line-height: 20px;
    }

    &__links {
      flex-wrap: nowrap;
      order: 3;
      margin-top: 30px
    }

    &__desc {
      order: 4;
      margin-top: 5px;
    }

    &__link {
      max-width: 130px;
      padding: 6px 19px;
    }

    &__link-img {
      width: 100px;
      height: 25px;
    }

  }
}
</style>
